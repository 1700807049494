@import '../utils/base';

.main-header.main-header {
  color: white;
  background-color: var(--brand-color) !important;

  .navbar-brand {
    color: inherit !important;
  }
}

.main-header__brand-logo {
  width: 44px;
  margin-right: 5px;
}

.main-header__toggle-icon {
  width: 20px;
  transition: transform 300ms;
}

.main-header__toggle-icon--opened {
  transform: rotate(180deg);
}
